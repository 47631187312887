import React, { useState, useContext, useEffect } from 'react';
import styled from 'styled-components';
import { ConfigurationContext } from '../../context';

const Wrapper = styled.div`
    order: ${props => props.order};
    >.inner{
        position: relative;
        display: flex;
        >.icon{
            top: 11px;
            left: 10px;
            font-size: 23px;
            position: absolute;
            color: #645FF9;
            
        }
        select{
            width: 100%;
            background-color: ${props => props.styles.background_color};
            border: 1px solid ${props => props.styles.border_color};
            border-radius: 6px;
            padding-left: 10px;
            padding-right: 10px;
            padding-top: 11px;
            padding-bottom: 11px;
            color: #484D63;
            font-size: 16px;
            line-height: 16px;
            max-height: 44px;
            :focus{
                outline: none !important;
                border-color: ${props => `rgb(${props.pancolors.dots})`};
            }
            :disabled{
                opacity: 0.5;
            }
        }
    }
    small{
        color: #484D63;
        margin-top: 5px;
    }
    .label{
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 3px;
        min-height: 20px;
        .label_icon{
            display: flex;
            margin-right: 5px;
            height: 20px;
            min-width: 20px;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            svg{
                float: left;
                height: 100%;
                fill: ${props => `rgb(${props.pancolors.black_overwhite})`};  
            }
        }
        label{
            font-size: 16px;
            line-height: 16px;
            color: #484D63;
            &.isRequired{
                :after{
                    content: " *";
                }
            }
        }
        
    }
    &.error{
        select{
            border: 2px solid ${props => `rgb(${props.pancolors.red})`};
        }
    }
`

const SelectCustom = (props) => {

    const { panColors } = useContext(ConfigurationContext);

    const { order,
            name,
            label,
            onChange,
            className,
            nulledOption = true,
            value,
            help,
            placeholder,
            icon,
            label_icon = null,
            styles = null,
            isRequired = false,
            data = null,
            disabled = false,
            index = null } = props;
        
    const [ normalizeData, setNormalizeData ] = useState(null);
    
    useEffect(() => {
        
        if(data){
            
            let nData = null;
            if(typeof data[0] == "string"){
                nData = data.map(d => {
                    return {value: d, description: d}
                })
            }
            if(typeof data[0] == "object"){
                nData = data.map(d => {
                    return {value: d._id, description: d.name}
                })
            }
            setNormalizeData(nData);
            
        }
    
      return () => {}
    }, [data])
    
    return (
        <Wrapper 
            pancolors={panColors} 
            styles={styles}
            className={className} 
            isRequired={isRequired}
            icon={icon ? true : false}
            order={order}
        >

            { label ? 
                <div className="label">
                    {label_icon && <div className="label_icon">{label_icon}</div>}
                    <label htmlFor={name} className={isRequired ? "isRequired" : ""}>{label}</label> 
                </div>
            : "" }

            <div className="inner">
                <select 
                    id={name} 
                    name={name} 
                    onChange={(e) => onChange(name, e.target.value, index)} 
                    value={value ? value : ""}
                    disabled={disabled}
                >
                    {(placeholder || label) && nulledOption ?
                    <option value={""} selected>{ placeholder ? placeholder : "Seleccione una opción" }</option>
                    : ""}
                    {normalizeData &&
                        normalizeData.map((el, i) => {
                            return(
                                <option value={el.value}>{el.description}</option>
                            )
                        })
                    }
                </select>
            </div>

            { help ? <small>{help}</small> : "" }

        </Wrapper>

    )

}

export default SelectCustom;