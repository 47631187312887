import React, { useContext } from 'react';
import styled from 'styled-components';
import { ConfigurationContext } from '../../context';
import Tooltip from '@mui/material/Tooltip';
import { HelpIcon } from '../../helpers/icons';

const Wrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5px;
    width: 100%!important;
    .label{
        display: flex;
        flex-direction: row;
        align-items: center;
        .label_icon{
            display: flex;
            margin-right: 5px;
            svg{
                fill: ${props => `rgb(${props.pancolors.black_overwhite})`};  
            }
        }
        label{
            font-size: 16px;
            line-height: 16px;
            color: #484D63;
            &.isRequired{
                :after{
                    content: " *";
                }
            }
        }
        
    }
    .help{
        display: flex;
        svg{
            display: flex;
        }
    }
`
const TooltipStyled = styled(Tooltip)`
    font-size: 24px;
    .tooltip_content{
        font-size: 16px;
    }
    
`

const LabelCustom = (props) => {

    const { panColors } = useContext(ConfigurationContext);

    const { name,
            label,
            //className,
            isRequired,
            label_icon = null,
            styles = null,
            tooltip_content = null, } = props;
            
    return (
        <Wrapper 
            pancolors={panColors}
            styles={styles}
            className={`label_custom label_${name}`}
        >
            
            <div className="label">
                {label_icon && <div className="label_icon">{label_icon}</div>}
                <label htmlFor={name} className={isRequired ? " isRequired" : ""}>{label}</label> 
            </div>

            {tooltip_content &&

            <TooltipStyled 
                title={tooltip_content}
                arrow 
                //open={true}
                PopperProps={{
                    sx: {
                      "& .MuiTooltip-tooltip": {
                        backgroundColor: `rgb(${panColors.dots})`,
                        color: "#FFFFFF",
                        padding: "17px 15px 17px 15px", 
                      },
                      ".MuiTooltip-arrow": {
                        color: `rgb(${panColors.dots})`,
                      },
                      ".tooltip_content": {
                        fontSize: "14px",
                        lineHeight: "19px"
                      }
                    }
                  }}
            >
                <div className="help">
                    
                        <HelpIcon />
                    
                </div>
            </TooltipStyled>
            }
        </Wrapper>

    )

}

export default LabelCustom;